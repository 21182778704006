<mat-label class="subHeading">{{label}}<span *ngIf="need" class="star">*</span></mat-label>

<div class="d-flex align-items-center mt-2" style="gap: 10px;" formGroupName="rule_date">
    <mat-form-field appearance="fill" class="custom-mat-form-field custom-datpicker">
        <mat-label>From Date</mat-label>
        <input
            matInput
            [min]="minDate"
            [ngxMatDatetimePicker]="picker1"
            placeholder="dd/mm/yyyy"
            [formControl]="start_dateControl"
        >
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
            #picker1
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [enableMeridian]="enableMeridian"
        >
        </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field *ngIf="!Erequired" appearance="fill">
      <mat-label>To Date</mat-label>
      <input
          matInput
          [min]= "enableEndDate ? end_dateControl.value: start_dateControl.value"
          [ngxMatDatetimePicker]="picker2"
          placeholder="dd/mm/yyyy"
          [formControl]="end_dateControl"
      >
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
          #picker2
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [enableMeridian]="enableMeridian">
      </ngx-mat-datetime-picker>
  </mat-form-field>

    <!-- <mat-form-field *ngIf="enableEndDate" appearance="fill">
      <mat-label>To Date</mat-label>
      <input
          matInput
          [min]="end_dateControl.value"
          [ngxMatDatetimePicker]="picker3"
          placeholder="dd/mm/yyyy"
          [formControl]="end_dateControl"

      >
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
          #picker3
          [showSpinners]="showSpinners"
          [showSeconds]="showSeconds"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="touchUi"
          [enableMeridian]="enableMeridian">
      </ngx-mat-datetime-picker>
  </mat-form-field> -->


</div>


<!-- <mat-form-field appearance="fill">
    <mat-label>To Date</mat-label>
    <input matInput [matDatepicker]="picker2" formControlName="end_date"  placeholder="dd/mm/yyyy" [min]="start_date.value">
    <mat-datepicker-toggle matIconSuffix [for]="picker2">
        <mat-icon matDatepickerToggleIcon>
            <img src="../../../../../assets/icons/calendar.svg">
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
</mat-form-field> -->
