import { Directive, Input, HostListener } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/dialog-components/confirm-dialog/confirm-dialog.component';


@Directive({
  selector: '[appConfirmAction]',
})
export class ConfirmActionDirective {
  @Input() confirmMessage: string = "Are you sure you want to perform this action?";
  @Input() confirmArgs: any[] = []; // Additional inputs for arguments
  @Input() confirmAction: (event:any, ...args: any[]) => void;  // Bind the action function to this property

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.confirmMessage }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.confirmAction) {
        this.confirmAction(event, ...this.confirmArgs);
      }
    });
  }
}
